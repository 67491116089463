.IconControls__popup___25Iy7 {
  transform-origin: top center;
}

.mapboxgl-popup-content {
  border-radius: calc(0.25rem * var(--scale, 1));
}

.IconControls__popup___25Iy7 .mapboxgl-popup-content {
  padding: 0;
  background-color: transparent;
  overflow: hidden;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.IconControls__container___2HNOM {
  display: flex;
  transform-origin: top left;
  background-color: white;
}

.IconControls__control___2XEkT:not(:last-child) {
  border-right: 1px solid lightgray;
}

.IconControls__icon___3TlrX {
  display: block;
  margin: 0 auto;
  width: 0.8rem;
  height: 0.8rem;
  pointer-events: none;
}
