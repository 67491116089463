.ErrorBar__container___1mjE_ {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  max-width: calc(100vw - 2rem);
  padding: 0.5rem 0 0.5rem 1rem;
  background-color: #d32f2f;
  color: white;
  z-index: 999;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
}

.ErrorBar__button___1UqyN {
  appearance: none;
  margin: 0;
  border: 0;
  background: none;
  color: white;
  padding: 0 1rem;
  font-size: 1rem;
  cursor: pointer;
  outline: none;
}
