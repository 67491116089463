*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  height: 100%;
  --primary-color: hsl(15, 70%, 68%);
  --secondary-color: hsl(201, 70%, 73%);
}

body {
  margin: 0;
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
  font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 1.5;
  background-color: #f0f4fb;
}

#__next {
  height: 100%;
}

p {
  margin: 0 0 1.5rem;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

img {
  display: block;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  line-height: 1.5;
}

input {
  width: 100%;
  border: solid 1px #ced5e7;
  border-radius: 0;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-color: white;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input:focus {
  outline: none;
  border-color: hsl(223, 34%, 60%);
}
