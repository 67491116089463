.ErrorMessage__container___1Ph2V {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f0f4fb;
  z-index: 99;
}

.ErrorMessage__content___V59uP {
  padding: 1rem;
  position: absolute;
  width: 100%;
  max-width: 420px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
  padding: 0;
  background-color: white;
  border-radius: 0.5rem;
  outline: none;
  overflow: hidden;
  z-index: 10;
  text-align: center;
  padding: 1.5rem;
}
