.UI__content___16KXm {
  padding: 1rem;
}

.UI__uiTitle___1gPKk {
  margin-bottom: 1rem;
}

.UI__container___uF71w {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.UI__scrollContent___2hByV {
  flex: 1;
  overflow: auto;
}

.UI__actions___3VW3m {
  border-top: 1px solid lightgray;
  z-index: 3;
}

.UI__infoList___FOYne {
  list-style: none;
  padding: 0;
}

.UI__infoList___FOYne li {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 0.5rem;
}

.UI__infoIcon___24Mio {
  position: absolute;
  left: 0;
  top: 50%;
  width: 1rem;
  height: 1rem;
  transform: translateY(-50%);
}

.UI__checkoutButton___1bHf- {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  align-items: center;
  text-align: left;
  font-weight: bold;
}

.UI__button___3YAcU {
  text-align: left;
  display: block;
  padding: 1.5rem;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5;
  color: white;
  border-radius: 1rem;
  background-color: var(--primary-color);
  box-shadow: 0 9px 22px 0 rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease, transform 0.2s ease;
}

.UI__button___3YAcU h3 {
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.UI__button___3YAcU:focus,
.UI__button___3YAcU:hover {
  box-shadow: 0 14px 28px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-0.1rem);
}

.UI__buttonSecondary___3evh1 {
  background-color: var(--secondary-color);
}
