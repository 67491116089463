.FormatButton__container___2a3En {
  position: relative;
  display: block;
  text-align: center;
  min-width: 140px;
  cursor: pointer;
  padding: 0.5rem;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  transition: background-color 0.2s ease;
}

.FormatButton__container___2a3En:hover {
  background-color: rgba(0, 0, 0, 0.025);
}

.FormatButton__containerSelected___1Ur7y,
.FormatButton__containerSelected___1Ur7y:hover {
  background-color: #b9e5fa;
}

.FormatButton__image___3U951 {
  object-fit: contain;
  margin: 0 auto;
}

.FormatButton__size___35iN2 {
  display: block;
  font-size: 80%;
}

.FormatButton__price___wWFzv {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid lightgrey;
  border-top-width: 0;
  border-left-width: 0;
  border-radius: 0 0 0.5rem 0;
  padding: 0 0.5rem;
  font-weight: bold;
}
