.BadgeOverlay__container___piGCB {
  position: absolute;
  bottom: 10.24px;
  left: 50%;
  transform: translateX(-50%);
}

.BadgeOverlay__rightAligned___2IP3E {
  right: 10.24px;
  left: auto;
  transform: none;
}

.BadgeOverlay__image___154X8 {
  display: block;
}

.BadgeOverlay__bottom___31Dy6 {
  bottom: -20.48px;
}

.BadgeOverlay__bottom___31Dy6 > img {
  height: 20px;
}
