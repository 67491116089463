.Modal__modal___wf58I {
  position: absolute;
  width: 90%;
  max-width: 420px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
  padding: 0;
  background-color: white;
  border-radius: 0.5rem;
  outline: none;
  overflow: hidden;
  z-index: 10;
  max-height: 90%;
  display: flex;
  flex-direction: column;
}

.Modal__large___13FSv {
  max-width: 1050px;
}

.Modal__overlay___36UJw {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
}

.Modal__title___33Xz2 {
  text-align: center;
  padding: 1.5rem;
}

.Modal__content___3JxL8 {
  padding: 0 1.5rem;
  margin-bottom: 1.5rem;
  overflow: auto;
}

.Modal__actions___1sR_b {
  display: grid;
  grid-auto-columns: minmax(min-content, 1fr);
  grid-auto-flow: column;
}
