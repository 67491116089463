.DeluxeModal__image___1rEWF {
  object-fit: contain;
}

.DeluxeModal__imageContainer___2s0B3 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
}

.DeluxeModal__grid___3szJ- {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  margin-bottom: 3rem;
}

@media (min-width: 700px) {
  .DeluxeModal__grid___3szJ- {
    grid-template-columns: repeat(5, 1fr);
  }
}

.DeluxeModal__column___vkizi {
  display: block;
  color: inherit;
  text-decoration: none;
  text-align: center;
}

.DeluxeModal__text___1vf4E {
  text-align: center;
}

.DeluxeModal__price___2JcCG {
  color: var(--primary-color);
  font-weight: bold;
  margin-bottom: 1rem;
}
