.Accordion__container___2NLAd {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Accordion__container___2NLAd .Accordion__container___2NLAd .Accordion__title___h28fq {
  padding-left: 1.5rem;
}

.Accordion__container___2NLAd .Accordion__container___2NLAd .Accordion__container___2NLAd .Accordion__title___h28fq {
  padding-left: 3rem;
}

.Accordion__container___2NLAd .Accordion__container___2NLAd .Accordion__container___2NLAd .Accordion__container___2NLAd .Accordion__title___h28fq {
  padding-left: 4rem;
}

.Accordion__title___h28fq {
  flex-shrink: 0;
  background-color: #eee;
  border-top: 1px solid lightgray;
  padding: 0.5rem 1rem calc(0.5rem - 1px);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  top: -1px;
  transition: background-color 0.2s ease;
  z-index: 1;
}

.Accordion__titleIcon___2TM9k {
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.5rem;
  transition: transform 0.2s ease;
}

.Accordion__title___h28fq:not(.Accordion__titleOpen___3Xzy7):hover {
  background-color: #f9f9f9;
}

.Accordion__titleOpen___3Xzy7 {
  border-bottom: 1px solid lightgray;
}

.Accordion__titleOpen___3Xzy7 .Accordion__titleIcon___2TM9k {
  transform: rotate(90deg);
}

.Accordion__content___3cZGQ {
  flex-grow: 1;
  overflow: auto;
}

.Accordion__accordionEnter___3YPSe {
  opacity: 0;
  transform: translateY(-2rem);
}

.Accordion__accordionEnterActive___30vDM {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.Accordion__accordionExit___34v0z {
  opacity: 1;
  transform: translateY(0);
}

.Accordion__accordionExitActive___1N4tw {
  opacity: 0;
  height: 0 !important;
  transform: translateY(-2rem);
  transition: opacity 0.2s ease, transform 0.2s ease, height 0.2s ease;
}
