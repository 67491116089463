.FormatSelector__container___11Zis,
.FormatSelector__formatContainer___2qTto {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.FormatSelector__formatContainer___2qTto {
  border-top: 1px solid lightgray;
}

.FormatSelector__button___ShNIE {
  margin: 10px;
}

.FormatSelector__title___125sP {
  margin: 0.5rem;
}
