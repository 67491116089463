.Tip__tip___3FwiF {
  padding: 0.5rem;
  font-size: 87.5%;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 0.5rem;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 0.5rem;
}

.Tip__tipIcon___10s-X {
  width: 1rem;
  height: 1rem;
}
