.PrefillSelector__container___1q_j2 {
  margin: 1rem 0 0.5rem;
}

.PrefillSelector__group___EhiYN {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, max-content);
  justify-content: space-between;
}

.PrefillSelector__item___nbs4G {
  --border-color: #eee;
  border-radius: 50%;
  border: 5px solid var(--border-color);
  width: 100px;
  height: 100px;
  overflow: hidden;
  padding: 0;
  transition: border-color 0.2s ease;
}

.PrefillSelector__itemSelected___1rv2Q {
  --border-color: #e69073;
}

.PrefillSelector__groupItem___3sqyf:not(:last-child) {
  border-radius: 50%;
}

.PrefillSelector__groupItem___3sqyf:not(:first-child) {
  border-radius: 50%;
  border-left: 5px solid var(--border-color);
  margin: 0;
}

.PrefillSelector__tooltip___1h3mL {
  max-width: 200px;
  text-align: center;
}

.PrefillSelector__tooltipTitle___3Y2RB {
  font-size: 112.5%;
  font-weight: bold;
}

.PrefillSelector__warning___1byAI {
}

.PrefillSelector__warningContent___2sSiz {
}
