.Header__container___LMgad {
  display: grid;
  grid-template-columns: max-content min-content;
  grid-gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.Header__content___2Q4Xf {
  display: grid;
  grid-gap: 1.5rem;
  grid-auto-flow: column;
  align-items: center;
}

.Header__title___1SCcV {
  display: none;
  margin: 0;
}

.Header__navigation___e0dYK {
  display: grid;
  grid-auto-columns: max-content;
  grid-gap: 1rem;
  grid-auto-flow: column;
}

@media (min-width: 1024px) {
  .Header__title___1SCcV {
    display: block;
  }
}
