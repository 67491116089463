.FileSystem__searchMask___k9RFk {
  display: grid;
  grid-template-columns: 1fr max-content;
}

.FileSystem__searchButton___w48_U {
  padding-right: 2.5rem;
}

.FileSystem__category___R-Pqs {
  border-bottom: 1px solid lightgray;
}

.FileSystem__category___R-Pqs .FileSystem__category___R-Pqs {
  grid-column: 1 / -1;
}

.FileSystem__category___R-Pqs .FileSystem__category___R-Pqs:last-child {
  border-bottom: 0;
}

.FileSystem__category__header___2OWcc {
  background-color: #e1f5fe;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

.FileSystem__category___R-Pqs[open] > .FileSystem__category__header___2OWcc {
  border-bottom: 1px solid lightgray;
}

.FileSystem__category___R-Pqs .FileSystem__category___R-Pqs .FileSystem__category__header___2OWcc {
  background-color: #eeeeee;
  padding: 0.25rem 1.5rem;
}

.FileSystem__category__content___3207g {
  padding: 0.5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.5rem;
}

.FileSystem__item___3cyDt {
  position: relative;
  cursor: grab;
  transition: background-color 0.2s ease;
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  border: 0;
  background-color: white;
  outline: none;
}

.FileSystem__item___3cyDt:after {
  display: block;
  content: "";
  height: 0;
  padding-bottom: 100%;
}

.FileSystem__itemImage___4qkMd {
  position: absolute;
  max-width: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
}

.FileSystem__item___3cyDt:hover {
  background-color: rgba(0, 0, 0, 0.025);
}
