.ScaleContainer__container___3Nmnv {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f0f4fb;
}

.ScaleContainer__content___3lyog {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.2s ease;
}

.ScaleContainer__fullscreenButton___2VJaL {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 0.25rem 0.25rem 0.25rem 0.3rem;
  border-bottom-left-radius: 0.5rem;
  background-color: white;
}
