.OverrideSelector__colorSwatch___1qjJf {
  width: 100%;
  height: auto;
  padding: 0;
  border: 0 !important;
  border-radius: 50% !important;
}

.OverrideSelector__colorSwatch___1qjJf.OverrideSelector__colorSwatchSelected___1tZYg {
  background-color: transparent;
}

.OverrideSelector__colorSwatch___1qjJf.OverrideSelector__colorSwatchSelected___1tZYg .OverrideSelector__colorSwatch__preview___3VyCd {
  border-width: 5px;
}

.OverrideSelector__colorSwatch__preview___3VyCd {
  display: block;
  width: 100%;
  border-radius: 50%;
  margin: 0 auto;
  cursor: pointer;
  transition: border-width 0.2s ease;
  border: 0 solid hsl(15, 70%, 68%);
}

.OverrideSelector__colorSwatch__preview___3VyCd:after {
  content: "";
  display: block;
  height: 0;
  width: 100%;
  padding-bottom: 100%;
}

.OverrideSelector__colorGrid___3wJ7X {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1rem;
  margin: 1rem 0 0.5rem;
}

.OverrideSelector__sliderGrid___2hAbc {
  display: grid;
  margin: 1rem 0 0.5rem;
  grid-template-columns: auto 1fr auto;
  grid-gap: 0.5rem;
  align-items: center;
}
