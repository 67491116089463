.InfoBox__container___J5lxM {
  position: relative;
  top: 0;
}

.InfoBox__content___1II0A {
  background-color: #fefefe;
  padding: 1rem 2.5rem 1rem 1rem;
  font-size: 87.5%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.InfoBox__button___2nKVs {
  position: absolute;
  right: 0;
  top: 0;
  appearance: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  border-width: 0;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-radius: 0 0 0 0.5rem;
  border-color: rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #fefefe;
  width: 1.8rem;
  height: 1.8rem;
  z-index: 3;
}

.InfoBox__icon___2CwbN {
  width: 1rem;
  height: 1rem;
  margin: 0 auto;
}
