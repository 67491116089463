.Map__container___33pX3 {
  border: 10.24px solid #afc2cb;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
}

.Map__containerPoster___2zs8t {
  border: 20.48px solid #fff;
}

.Map__containerPoster___2zs8t .overlays {
  overflow: visible !important;
}

.Map__containerRound___14YYr {
  position: relative;
  border-radius: 50%;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
}

.Map__containerRound___14YYr .mapboxgl-map {
  position: relative;
  border-radius: 50%;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
}
